import React, {useContext, useEffect, useState} from 'react';
import {Document, Page, Text, View, StyleSheet, pdf} from '@react-pdf/renderer';
import {MainContext} from "../../../context/MainContext";
import api from "../../../services/api";

const styles = StyleSheet.create({
    page: {
        paddingBottom: 40,
        paddingTop: 19,
        paddingLeft: 20,
        paddingRight: 20,
        fontSize: 8,
        flexDirection: 'column',
        position: 'relative',
    },
    boldText: {
        fontWeight: 'bold',
    },
    section: {
        marginBottom: 20,
    },
    table: {
        margin: 'auto',
        width: '100%',
        borderWidth: 0.5,
        borderColor: '#000',
        borderCollapse: 'collapse',
    },
    tableRow: {
        flexDirection: 'row',},
    tableCell: {
        padding: 2.5,
        borderWidth: 0.5,
        borderColor: '#000',
        borderCollapse: 'collapse',
        flexGrow: 1,
    },

});

const AnnuityPrinting:  React.FC<any> = ({ childToParent }:any) => {
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [annuities, setAnnuities] = useState<any>([])

    const {setLoading} = useContext(MainContext)

    async function getAnnuities() {
        await api.post(`/api/annuities/report`).then((response) => {
            const data = response.data
            setAnnuities(data)
        })
    }

    const handleOpenPDF = async () => {
        if (dataLoaded){
            const blob = await pdf(<PDF />).toBlob();
            const url = URL.createObjectURL(blob);
            window.open(url);
        }
    };

    useEffect(() => {
        setLoading(true)
        const loadData = async () => {
            await new Promise(async (resolve) => {
                resolve([
                    await getAnnuities(),
                ]);
            });

            setDataLoaded(true);
        };
        loadData().then(r => setLoading(false));
    }, []);

    useEffect(() => {
        if (dataLoaded) {
            handleOpenPDF().then(r => {
                if (childToParent) {
                    childToParent('imprimiu');
                }
            });
        }
    }, [dataLoaded]);

    const PDF = () => (
        <Document>
            <Page style={styles.page} size={"A4"} >
                <View style={styles.section}>
                    <View style={{ textAlign: 'center' }}>
                        <Text>&nbsp;</Text>
                        <Text style={{fontWeight: 'bold' ,marginTop:3,marginBottom:8 }}>Relatório de anuidade</Text>
                    </View>

                    <Text>&nbsp;</Text>
                    <Text>&nbsp;</Text>

                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '12%' }]}>Nº</Text>
                            <Text style={[styles.tableCell, { width: '55%' }]}> Nome</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>2025</Text>
                        </View>

                        {annuities?.map((annuity: any, index: number) =>
                            <View style={styles.tableRow}>
                            <Text style={[styles.tableCell, { width: '12%' }]}>{annuity?.member_number}</Text>
                            <Text style={[styles.tableCell, { width: '55%' }]}>{annuity?.full_name}</Text>
                            <Text style={[styles.tableCell, { width: '33%' }]}>{annuity?.paid ? "Pago" : "Não pago"}</Text>
                        </View>
                        )}

                    </View>
                </View>
            </Page>
        </Document>
    );

    return null
};

export default AnnuityPrinting;
