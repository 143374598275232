import {Link} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../context/AuthContext";
import {MainContext} from "../context/MainContext";
import api from "../services/api";

export default function Home() {
    const { authUser } = useContext(AuthContext)
    const {setPageTitle, setLoading} = useContext(MainContext)
    const [ infos, setInfos ] = useState<any>({})


    useEffect(() => {
        setLoading(true)
        setPageTitle("Inicio")
        const fetchData = async () => {
            await getDataDashboard()
        };

         fetchData().then(()=>{
            setLoading(false)
         })
    }, []);

    async function getDataDashboard() {
        await api.post('/api/dashboard').then((response) => {
            setInfos(response.data)
        })
    }

    return (
        <section className="section dashboard">

            <div className="col-lg-12 mt-3">
                <div className="row">

                    {/*<div className="alert alert-warning alert-dismissible" role="alert">*/}
                    {/*    <div>*/}
                    {/*        <i className="bi bi-exclamation-triangle-fill">&nbsp;&nbsp;</i>*/}
                    {/*        Nice, you triggered this alert*/}
                    {/*        message! <a href="#" className="alert-link">Clique para mais detalhes</a>*/}
                    {/*    </div>*/}
                    {/*    <button type="button" className="btn-close" data-bs-dismiss="alert"*/}
                    {/*            aria-label="Close"></button>*/}
                    {/*</div>*/}

                    <Link to={"/habitualities/create"} className="col-xxl-4 col-md-6">
                        <div className="card info-card revenue-card">

                            <div className="card-body">
                                <h5 className="card-title">{authUser?.role === 'athlete' ? "Minhas habitualidades" : "Habitualidades"}</h5>

                                <div className="d-flex align-items-center">
                                    <div
                                        className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                        <i className="bi bi-calendar-event"></i>
                                    </div>
                                    <div className="ps-3">
                                        <h6>{infos?.habitualities || ""} </h6>
                                        {infos?.shots &&
                                            <>
                                                <span
                                                    className="text-success small pt-1 fw-bold">{infos?.shots || ''}</span>
                                                <span className="text-muted small pt-2 ps-1">disparos</span>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>

                    {authUser?.role === 'athlete' &&
                        <Link to={"/weapons"} className="col-xxl-4 col-md-6">
                            <div className="card info-card customers-card">

                                <div className="card-body">
                                    <h5 className="card-title">Meu acervo</h5>

                                    <div className="d-flex align-items-center">
                                        <div
                                            className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-bullseye"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6>{infos?.weapons || ""} </h6>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    }

                    {['coordinator', 'admin'].includes(authUser?.role) &&
                        <Link to={"/members"} className="col-xxl-4 col-md-6">
                            <div className="card info-card customers-card">

                                <div className="card-body">
                                    <h5 className="card-title">Meus socios</h5>

                                    <div className="d-flex align-items-center">
                                        <div
                                            className="card-icon rounded-circle d-flex align-items-center justify-content-center">
                                            <i className="bi bi-people-fill"></i>
                                        </div>
                                        <div className="ps-3">
                                            <h6>{infos?.menbers || ""} </h6>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Link>
                    }

                </div>
            </div>

        </section>
    );
}
