
import {useContext, useEffect} from "react";
import {MainContext} from "../../context/MainContext";
import ListingAthletes from "../Athlete/listing";
import {AnnuityProvider} from "../../context/Annuity/AnnuityContext";
import AnnuitiesForm from "./form";

export default function FormAnnuityWithContext() {
    const {setPageTitle} = useContext(MainContext)

    useEffect(() => {
        setPageTitle("Pagamento de anuidade")
    }, []);

  return (
      <>
        <AnnuityProvider>
            <AnnuitiesForm/>
        </AnnuityProvider>
      </>
  )
}
