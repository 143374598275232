import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import api from "../../services/api";
import Swal from "sweetalert2";
import {MainContext} from "../../context/MainContext";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import Select from "react-select";
import IAnnuity from "../../interfaces/IAnnuity";
import dayjs from "dayjs";
import { useAnnuity } from '../../context/Annuity/AnnuityContext';


export default function AnnuitiesForm() {
    const navigate = useNavigate();
    const { setLoading } = useContext(MainContext)

    const { amount ,setAmount,
        year,setYear,
        paymentMethod,setPaymentMethod,
        setAthleteId,
        arrayPaymentsMethods,
        validateAnnuityRegister,
        dispatchAnnuitiesObject} = useAnnuity();

    let { athleteId} = useParams()
    const [id, setId] = useState(undefined);
    const [annuities, setAnnuities] = useState<any>([])


    useEffect(() => {
        setLoading(true)
            if (athleteId != undefined) {
                setAthleteId(parseInt(athleteId))
            }

        getAnnuities()

        const fetchData = async () => {
            if (id != undefined) {
               await api.get<IAnnuity>('/api/annuities/' + id).then(resposta => {
                    const annuity = resposta.data

                    setAmount(annuity?.amount)
                    setPaymentMethod(annuity?.amount)
                    setYear(annuity?.year != null ? dayjs(annuity?.year) : null)
               })
            }
        };

        fetchData().then(setLoading(false))
    },  [])

    const getAnnuities = async (id: number = 0) => {
        setLoading(true)
        try {
            await api.post(`/api/annuities/my/${athleteId}`).then(async (response) => {
                setAnnuities(response.data)
            })
        }catch (e){
            console.log(e)
        }finally {
            setLoading(false)
        }
    }

    const resgister = (evento: React.FormEvent<HTMLFormElement>) => {
        try {
            evento.preventDefault();
            validateAnnuityRegister()

            setLoading(true)


            const method:'post' | 'put' = id === undefined ? 'post' : 'put';
            const url:string = id === undefined ? '/api/annuities' : `/api/annuities/${id}`;

            api[method](url, dispatchAnnuitiesObject)
                .then((result) => {
                    setLoading(false)
                    Swal.fire({
                        title: 'Sucesso!',
                        text: result.data.message,
                        icon: 'success',
                        timer: 3000
                    }).then(() => {
                        return navigate("/members");
                    });
                })
                .catch(erro => {
                    setLoading(false)
                    Swal.fire({
                        title: 'Erro!',
                        text: erro.response.data.error,
                        icon: 'error',
                    });
                });
        }catch (err: any) {
            setLoading(false)
            Swal.fire({
                title: 'Erro!',
                text: err,
                icon: 'error',
            })
        }

    }

    return (
        <>
            <form onSubmit={resgister} className="row g-3">
                <div className="card">
                    <div className="card-body">
                        <div className="row g-3 mt-2">
                            <div className={"col-md-3"}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                    <label htmlFor="associationDate" className="form-label">Ano do
                                        pagamento <i
                                            className="text-danger"> *</i></label>
                                    <DatePicker className={"form-control sadate"}
                                                views={['year']}
                                                value={year}
                                                onChange={evento => setYear(evento)}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className={"col-md-4"}>
                                <label htmlFor="quantity_shots" className="form-label">Valor <i
                                    className="text-danger"> *</i></label>
                                <input type="number" className="form-control" id="quantity_shots"
                                       placeholder="0"
                                       onChange={evento => setAmount(parseInt(evento.target.value))}
                                       value={amount}/>
                            </div>

                            <div className={"col-md-5"}>

                                <label htmlFor="payment_method_id" className="form-label">Forma de pagamento <i
                                    className="text-danger"> *</i></label>
                                <Select
                                    options={arrayPaymentsMethods}
                                    name='payment_method_id'
                                    id='payment_method_id'
                                    onChange={(data: any) => {
                                        setPaymentMethod(data.value)
                                    }}
                                    noOptionsMessage={() => 'Nenhum registro encontrado'}
                                    value={arrayPaymentsMethods.find((option: {
                                        value: any;
                                    }) => option.value == paymentMethod) || ''}
                                    placeholder='Forma de pagamento'
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-success me-2">Salvar</button>
                                <Link to={"/members"} className="btn btn-danger">Cancelar</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </form>

            <div className="card">
                <div className="card-body">
                    <div className="row g-3 mt-2">
                        <div className="col-md-12">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Data de Pagamento</th>
                                    <th scope="col">Ano</th>
                                    <th scope="col">Valor pago</th>
                                    <th scope="col">Forma de pagamento</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>
                                {(annuities != undefined && annuities.length > 0) ? (
                                    <>
                                        {annuities.map((res: any, index: number) =>
                                            <tr key={`${index}`}>
                                                <th scope='row'>{new Date(res?.payment_date).toLocaleString('pt-BR')}</th>
                                                <td>{res?.year}</td>
                                                <td>{res?.amount}</td>
                                                <td>{res?.payment_method_id}</td>
                                                <td>
                                                                <span
                                                                    className={`badge rounded-pill ${res?.paid ? 'bg-success' : 'bg-danger'}`}>{res?.paid ? 'Pago' : 'Atrasado'}</span>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ) : (
                                    <tr>
                                        <td colSpan={5} className='text-center'>
                                            Nenhuma anuidade paga
                                        </td>
                                    </tr>
                                )}

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

