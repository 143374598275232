import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {AuthContext} from "../../context/AuthContext";

export default function Sidebar() {
    const { authUser } = useContext(AuthContext)

    return (
      <>
          <aside id="sidebar" className="sidebar">

              <ul className="sidebar-nav" id="sidebar-nav">

                  <li className="nav-item">
                      <Link className="nav-link " to={"/"}>
                          <i className="bi bi-grid"></i>
                          <span>Inicio</span>
                      </Link>
                  </li>

                  <li className="nav-item">
                      <a className="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
                          <i className="bi bi-layout-text-window-reverse"></i><span>Menus</span><i
                          className="bi bi-chevron-down ms-auto"></i>
                      </a>
                      <ul id="tables-nav" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                          {['coordinator', 'admin'].includes(authUser?.role) &&
                              <>
                                  <li>
                                      <Link to={"/members"}>
                                          <span className="bi bi-people-fill">&nbsp;</span><span>Sócios</span>
                                      </Link>
                                  </li>

                                  <li>
                                      <Link to={"/members/report"}>
                                          <span className="bi bi-people-fill">&nbsp;</span><span>Relatório de Sócios</span>
                                      </Link>
                                  </li>

                                  <li>
                                      <Link to={"/events"}>
                                          <span className="bi bi-list-nested">&nbsp;</span><span>Eventos</span>
                                      </Link>
                                  </li>

                                  {/*<li>*/}
                                  {/*    <Link to={"/paymentMethods"}>*/}
                                  {/*        <span*/}
                                  {/*            className="bi bi-coin">&nbsp;</span><span>Formas de pagamento</span>*/}
                                  {/*    </Link>*/}
                                  {/*</li>*/}
                              </>
                          }

                          {authUser?.role === 'athlete' &&
                              <>
                                  <li>
                                      <Link to={"/weapons"}>
                                          <span className="bi bi-bullseye">&nbsp;</span><span>Armas</span>
                                      </Link>
                                  </li>
                              </>
                          }

                          <li>
                              <Link to={"/habitualities/create"}>
                                  <span className="bi bi-list-columns">&nbsp;</span><span>Habitualidades</span>
                              </Link>
                          </li>

                      </ul>
                  </li>

                  {authUser?.role === 'admin' &&
                      <li className="nav-item">
                          <a className="nav-link collapsed" data-bs-target="#tables-nav-1" data-bs-toggle="collapse" href="#">
                              <i className="bi bi-layout-text-window-reverse"></i><span>Gerencia</span><i
                              className="bi bi-chevron-down ms-auto"></i>
                          </a>
                          <ul id="tables-nav-1" className="nav-content collapse" data-bs-parent="#sidebar-nav">
                                  <>
                                      <li>
                                          <Link to={"/admin/users"}>
                                              <span className="bi bi-list-nested">&nbsp;</span><span>Usuarios</span>
                                          </Link>
                                      </li>

                                      <li>
                                          <Link to={"/admin/clubs"}>
                                              <span className="bi bi-circle">&nbsp;</span><span>Clubes</span>
                                          </Link>
                                      </li>
                                  </>
                          </ul>
                      </li>
                  }

              </ul>

          </aside>
      </>
    );
}
