import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import IAnnuity from "../../interfaces/IAnnuity";
import dayjs, {Dayjs} from "dayjs";
import api from "../../services/api";
import IPaymentMethod from "../../interfaces/IPaymentMethod";

type Props = {
    children?: ReactNode;
}

interface AnnuityContextType {
    year: Dayjs | null;
    setYear: (year: dayjs.Dayjs | null) => void;
    paymentMethod: number;
    setPaymentMethod: (paymentMethod: number) => void;
    amount: number;
    setAmount: (amount: number) => void;
    athleteId: number;
    setAthleteId: (athleteId: number) => void;
    paid: boolean;
    setPaid: (paid: boolean) => void;
    paymentDate: Dayjs | null;
    setPaymentDate: (paymentDate: dayjs.Dayjs | null) => void;
    arrayPaymentsMethods: any ;
    setArrayPaymentsMethods: (arrayPaymentsMethods: any ) => void;
    validateAnnuityRegister: () => void;
    dispatchAnnuitiesObject: IAnnuity;
}

const AnnuityContext = createContext<AnnuityContextType | undefined>(undefined);

const AnnuityProvider = ({children}: Props) => {
    const [year, setYear] = useState<Dayjs | null>(dayjs());
    const [paymentMethod, setPaymentMethod] = useState<number>(0);
    const [amount, setAmount] = useState<number>(0);
    const [athleteId, setAthleteId] = useState<number>(1);
    const [paid, setPaid] = useState<boolean>(true);
    const [paymentDate, setPaymentDate] = useState<Dayjs | null>(dayjs());

    const [arrayPaymentsMethods, setArrayPaymentsMethods] = useState<any>([])

    async function paymentsMethods(value = '') {
        const response = await api.get(`/api/paymentsMethods`)
        const result = await response
        return setArrayPaymentsMethods(
            await result.data.map((paymentMethod:IPaymentMethod) => ({
                label: paymentMethod.description ,
                value: paymentMethod.id
            })),
        )
    }

    const validateAnnuityRegister = () => {
        if (!year) {
            throw "Ano deve ser informado";
        }

        if (!amount) {
            throw "Valor deve ser informada";
        }

        if (!paymentMethod) {
            throw "Forma de pagamento deve ser selecionada";
        }
    }

    let dispatchAnnuitiesObject:IAnnuity = {
        // id: addressId,
        athlete_id: athleteId,
        year: year != null ? dayjs(year).format("YYYY") : null,
        amount: amount,
        paid: paid,
        payment_date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        payment_method_id: paymentMethod,
    }

    useEffect(() => {
        paymentsMethods()
    },  [])

    return (
     <>
        <AnnuityContext.Provider value={{
            year, setYear,
            paymentMethod, setPaymentMethod,
            amount, setAmount,
            athleteId, setAthleteId,
            paid, setPaid,
            paymentDate, setPaymentDate,
            arrayPaymentsMethods, setArrayPaymentsMethods,
            validateAnnuityRegister,
            dispatchAnnuitiesObject
        }}>
            {children}
        </AnnuityContext.Provider>
     </>
    )
}
export const useAnnuity = (): AnnuityContextType => {
    const context = useContext(AnnuityContext);
    if (!context) {
        throw new Error('useAnnuity must be used within an AnnuityProvider');
    }
    return context;
};

export { AnnuityContext, AnnuityProvider }
