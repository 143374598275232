import React, {useState} from "react";
import AnnuityPrinting from "./Report/AnnuityPrinting";

export default function AnnuityReport() {
    const [print, setPrint] = useState<any>({})

    const childToParent = (childdata:any) => {
        setPrint({imprimir:false})
    }

    return (
        <>
            <div>
                <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalAnnuities">
                  <i className="bi bi-cash-coin" aria-hidden="true"></i>&nbsp; Relatório de Anuidades
                </button>

                <div className="modal fade" id="modalAnnuities" tabIndex={-1} aria-labelledby="modalAnnuitiesLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="modalAnnuitiesLabel">Relatório de Anuidades</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <button type={"button"} className="btn btn-primary mt-2"
                                        onClick={() => setPrint({
                                            imprimir: true
                                        })}>
                                    <i className="bi bi-printer-fill"></i> Imprimir
                                </button>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Sair
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {print.imprimir &&
                <AnnuityPrinting childToParent={childToParent}/>
            }
        </>
    )
}
