import React, {useContext, useEffect, useRef, useState} from "react";
import {PasswordContext} from "../../context/PasswordContext";
import Swal from "sweetalert2";

export default function PasswordForm({changePassword = false , customLabel={}}:any) {
    const { validatePassword, setPassword, setPasswordConfirmation, setCurrentPassword
        ,checkPolity, upperCase, lowerCase, numbers, special, size, password , passwordConfirmation} = useContext(PasswordContext)

    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [capsLockOn, setCapsLockOn] = useState(false);
    const [showPolity, setShowPolity] = useState(false);
    const inputRefs = useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            const isCapsLockOn = e.getModifierState('CapsLock');
            setCapsLockOn(isCapsLockOn);
        };

        inputRefs.current.forEach((inputRef) => {
            inputRef.addEventListener('keydown', handleKeyDown);
            inputRef.addEventListener('focusout', handleFocusOut);
        });

        return () => {
            inputRefs.current.forEach((inputRef) => {
                inputRef.removeEventListener('keydown', handleKeyDown);
                inputRef.removeEventListener('focusout', handleFocusOut);
            });
        };
    }, []);

    const handleFocusOut = () => {
        setCapsLockOn(false);
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    const checkPassword = () => {
        if (!validatePassword()){
            Swal.fire({
                title: 'Erro!',
                text: 'A senha deve seguir a regra de segurança!',
                icon: 'error',
            })
        }
    }

    return (
        <>
            {changePassword &&
                <div className="col-md-12">
                    <div className="col-md-6">
                        <label htmlFor="currentPassword" className="form-label">{!customLabel?.currentPassword ? "Senha atual" : customLabel?.currentPassword} <i className="text-danger"> *</i></label>
                        <div className="input-group ">
                            <input ref={(ref) => ref && inputRefs.current.push(ref)}
                                   type={isPasswordVisible ? 'text' : 'password'}
                                   className="form-control" id="currentPassword" placeholder="******"
                                   onChange={evento => setCurrentPassword(evento.target.value)}/>
                            <span className="input-group-text" id="inputGroupPrepend" onClick={togglePasswordVisibility}>
                            <i className={`bi fa-fw field-icon toggle-password ${isPasswordVisible ? 'bi-eye-slash-fill' : 'bi-eye-fill'}`}></i> </span>
                        </div>
                    </div>
                </div>
            }

            <div className="col-md-6">
                <label htmlFor="password"
                       className="form-label">{!customLabel?.password ? "Senha" : customLabel?.password} <i
                    className="text-danger"> *</i> </label>
                <div className="input-group ">
                    <input ref={(ref) => ref && inputRefs.current.push(ref)}
                           type={isPasswordVisible ? 'text' : 'password'} className="form-control" id="password"
                           placeholder="******"
                           onKeyUp={event => checkPolity()}
                           onBlur={event => {
                               checkPassword()
                               setShowPolity(false)
                           }}
                           onFocus={event => {
                               checkPolity()
                               setShowPolity(true)
                           }}
                           onChange={evento => setPassword(evento.target.value)}/>
                    <span className="input-group-text" id="inputGroupPrepend" onClick={togglePasswordVisibility}>
                        <i className={`bi fa-fw field-icon toggle-password ${isPasswordVisible ? 'bi-eye-slash-fill' : 'bi-eye-fill'}`}></i> </span>
                </div>

                {showPolity &&
                <div className="m-2">
                    <p><b>Sua senha deve ter no mínimo</b></p>
                    <ul className="list-unstyled">
                        <li className="">
                                        <span className="eight-character">
                                            <i className={`bi ${size ? 'bi-check-lg text-success':'bi-x-lg text-danger'}`} aria-hidden="true"></i>
                                            &nbsp;Pelo menos 8 caracteres
                                        </span>
                        </li>
                        <li className="">
                                        <span className="low-upper-case">
                                            <i className={`bi ${upperCase & lowerCase ? 'bi-check-lg text-success':'bi-x-lg text-danger'}`} aria-hidden="true"></i>
                                            &nbsp;1 Letra Maiúscula e 1 Minúscula
                                        </span>
                        </li>
                        <li className="">
                                        <span className="one-number">
                                            <i className={`bi ${numbers ? 'bi-check-lg text-success':'bi-x-lg text-danger'}`} aria-hidden="true"></i>
                                            &nbsp; 1 Número (0-9)
                                        </span>
                        </li>
                        <li className="">
                                        <span className="one-special-char">
                                            <i className={`bi ${special ? 'bi-check-lg text-success':'bi-x-lg text-danger'}`} aria-hidden="true"></i>
                                            &nbsp; 1 Caractere especial (l@#$%&*)
                                        </span>
                        </li>
                    </ul>
                </div>
                }
            </div>

            <div className="col-md-6">
                <label htmlFor="passwordConfirmation"
                       className="form-label">{!customLabel?.passwordConfirmation ? "Confirme a senha" : customLabel?.passwordConfirmation}
                    <i className="text-danger"> *</i></label>
                <div className="input-group ">
                    <input ref={(ref) => ref && inputRefs.current.push(ref)}
                           type={isPasswordVisible ? 'text' : 'password'} className="form-control" id="passwordConfirmation" placeholder="******"
                           onBlur={event => {
                               // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                               ( password != passwordConfirmation ) ? (
                                   Swal.fire({
                                       title: 'Erro!',
                                       text: 'Senhas devem ser iguais!',
                                       icon: 'error',
                                   })
                               ): "";
                           }}
                           onChange={evento => setPasswordConfirmation(evento.target.value)}/>
                    <span className="input-group-text" id="inputGroupPrepend" onClick={togglePasswordVisibility}>
                        <i className={`bi fa-fw field-icon toggle-password ${isPasswordVisible ? 'bi-eye-slash-fill' : 'bi-eye-fill'}`}></i> </span>
                </div>
            </div>

            {capsLockOn &&
                <div className="col g-3">
                    <span className="text-danger"><b>Caps lock está ligado</b></span>
                </div>
            }

        </>
    );
}
